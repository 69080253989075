import Loadable from 'react-loadable';

import { CustomLoadableLoading } from '../../components/loading/loading.js';

const WhoAreWePageAsync = Loadable({
	loader: () => import(/* webpackChunkName: "who-are-we-page" */'./who-are-we.page.js'),
	modules: ['./who-are-we.page.js'],
	webpack: () => [require.resolveWeak('./who-are-we.page.js')],
	loading: CustomLoadableLoading,
});

export default WhoAreWePageAsync;
