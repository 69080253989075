import Loadable from 'react-loadable';

import { CustomLoadableLoading } from '../../components/loading/loading.js';

const OurValuesPageAsync = Loadable({
	loader: () => import(/* webpackChunkName: "our-values-page" */'./our-values.page.js'),
	modules: ['./our-values.page.js'],
	webpack: () => [require.resolveWeak('./our-values.page.js')],
	loading: CustomLoadableLoading,
});

export default OurValuesPageAsync;
