import React from 'react';
import { withRouter } from 'react-router';

class GoogleAnalytics extends React.Component {
	componentDidUpdate(prevProps) {
		const { location } = this.props;

		if (
			process.env.NODE_ENV === 'production'
			&& process.env.REACT_APP_GA_ID
			&& window.gtag
			&& prevProps.location.pathname !== location.pathname
		) {
			window.gtag('event', 'page_view', location.pathname);
			window.gtag('config', process.env.REACT_APP_GA_ID, {
				page_path: location.pathname,
			});
		}
	}

	render() {
		const { children } = this.props;

		return children;
	}
}

export default withRouter(GoogleAnalytics);
