const defaultSupportedLanguage = {
	value: 'en',
	label: 'English',
};

const supportedLanguages = [
	defaultSupportedLanguage,
	{
		value: 'pt',
		label: 'Português',
	},
	{
		value: 'es',
		label: 'Español',
	},
	{
		value: 'fr',
		label: 'Français',
	},
	{
		value: 'zh',
		label: '普通話',
	},
];

export { defaultSupportedLanguage, supportedLanguages };
