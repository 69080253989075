import Loadable from 'react-loadable';

import { CustomLoadableLoading } from '../../components/loading/loading.js';

const OurTeamPageAsync = Loadable({
	loader: () => import(/* webpackChunkName: "our-team-page" */'./our-team.page.js'),
	modules: ['./our-team.page.js'],
	webpack: () => [require.resolveWeak('./our-team.page.js')],
	loading: CustomLoadableLoading,
});

export default OurTeamPageAsync;
