import React, { Fragment } from 'react';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
// eslint-disable-next-line import/extensions
import { connect } from 'redux-zero/react';

import { actions } from '../shared/store.js';
import MainService from '../main-service.js';

import icFacebook from '../assets/icons/ic-facebook.svg';
import icInstagram from '../assets/icons/ic-instagram.svg';
import icLinkedin from '../assets/icons/ic-linkedin.svg';
import icTwitter from '../assets/icons/ic-twitter.svg';
import icYoutube from '../assets/icons/ic-youtube.svg';
import illuFooter from '../assets/illustrations/illu-footer.svg';

import './footer.scss';

class Footer extends React.Component {
	static prefetchData = async language => Promise.all([
		MainService.getGeneralData(language),
		MainService.getContactsData(language),
	]);

	componentDidMount() {
		const { generalData, addresses, phones } = this.props;

		if (
			generalData.length === 0
			|| addresses.length === 0
			|| phones.length === 0
		) {
			this.fetchData();
		}
	}

	componentDidUpdate(prevProps) {
		const { language } = this.props;

		if (prevProps.language.value !== language.value) {
			this.fetchData();
		}
	}

	handleLang = (selectedLang) => {
		const { changeLanguage } = this.props;
		changeLanguage(selectedLang);
	};

	fetchData() {
		const {
			language, setGeneralData, setAddresses, setPhones,
		} = this.props;

		Footer.prefetchData(language.value)
			.then(([{ data }, { addressesList, phonesList }]) => {
				setGeneralData(data);
				setAddresses(addressesList);
				setPhones(phonesList);
			})
			.catch((error) => {
				console.log(error);
			});
	}

	render() {
		const {
			language, languages, generalData, addresses, phones,
		} = this.props;

		return (
			<footer className="site-footer">
				<div className="container container-fluid">
					<div className="row">
						<div className="col-xs-12 col-md-3 start-xs top-xs">
							{generalData
								&& generalData.logo
								&& generalData.logo.path && (
								<FooterLogo path={generalData.logo.path} />
							)}
						</div>
						<div className="col-xs-12 col-md-9 start-xs top-xs gutterless">
							<div className="row">
								<div className="col-xs-6 col-md-3">
									<FooterNav>
										<FooterNavItem
											path="/about/who-are-we"
											label="navigation.about.whoAreWe"
										/>
										<FooterNavItem path="/news" label="navigation.news" />
										<FooterNavItem
											path="/how-it-works/seeker"
											label="navigation.howItWorks.seeker"
										/>
										<FooterNavItem
											path="/how-it-works/solver"
											label="navigation.howItWorks.solver"
										/>
										<FooterNavItem
											path="/success-stories"
											label="navigation.successStories"
										/>
										<FooterNavItem path="/careers" label="navigation.careers" />
										<FooterNavItem
											path="/partners"
											label="navigation.partners"
										/>
									</FooterNav>
								</div>
								<div className="col-xs-4 col-md-3">
									{addresses && <FooterContactAddress addresses={addresses} />}
								</div>
								<div className="col-xs-12 col-md-3">
									{phones && <FooterContactPhone phones={phones} />}
								</div>
								<div className="col-xs-6 col-md-3 language-and-terms">
									<I18N
										languages={languages}
										selectedLang={language}
										handleLang={this.handleLang}
									/>
									<PrivacyAndTerms />
									<Faqs />
									<SocialLinks />
								</div>
							</div>
						</div>
					</div>
				</div>
				<hr />
				<div className="container container-fluid">
					<div className="row">
						<div className="col-xs-6 col-md-12 center-md bottom-xs">
							<FooterCopyright year={new Date().getFullYear()} />
						</div>
						<div className="col-xs-12 col-md-12 footer-illustration-sector">
							<img
								className="footer-illustration"
								src={illuFooter}
								alt=""
								aria-hidden
							/>
						</div>
					</div>
				</div>
			</footer>
		);
	}
}

const FooterLogo = ({ path }) => (
	<Link to="/">
		<img
			className="logo"
			src={process.env.REACT_APP_API_ENDPOINT + path}
			alt=""
		/>
	</Link>
);

const FooterNav = ({ children }) => (
	<div className="navigation">
		<h5 className="column-title">Inocrowd</h5>
		<ul className="navigation-list">{children}</ul>
	</div>
);

const FooterNavItem = ({ path, label }) => (
	<li className="navigation-item">
		<Link to={path}>
			<FormattedMessage id={label}>
				{txt => <Fragment>{txt}</Fragment>}
			</FormattedMessage>
		</Link>
	</li>
);

const FooterContactAddress = ({ addresses }) => (
	<div className="addresses">
		<h5 className="column-title">
			<FormattedMessage id="footer.address.title">
				{txt => <Fragment>{txt}</Fragment>}
			</FormattedMessage>
		</h5>
		<ul className="addresses-list">
			<a
				href="https://goo.gl/maps/2LEvBjZerLu"
				target="_blank"
				rel="noopener noreferrer"
			>
				{addresses.map(item => (
					<FooterContactAddressItem item={item} key={item._id} />
				))}
			</a>
		</ul>
	</div>
);

const FooterContactAddressItem = ({ item }) => (
	<li className="address-item">
		<p dangerouslySetInnerHTML={{ __html: item.description }} />
	</li>
);

const FooterContactPhone = ({ phones }) => (
	<div className="phones">
		<h5 className="column-title">
			<FormattedMessage id="footer.phone.title">
				{txt => <Fragment>{txt}</Fragment>}
			</FormattedMessage>
		</h5>
		<ul className="addresses-list">
			{phones.map(item => (
				<FooterContactPhoneItem item={item} key={item._id} />
			))}
		</ul>
	</div>
);

const FooterContactPhoneItem = ({ item }) => (item.phoneNumber ? (
	<li className="phone-item">
		<a href={`tel:${item.phoneNumber.replace(/\s/g, '')}`}>
			{item.phoneNumber}
		</a>
	</li>
) : null);

const I18N = ({ languages, selectedLang, handleLang }) => (
	<div
		className="i18n"
		style={{ width: `${11 * selectedLang.label.length + 30}px` }}
	>
		<Select
			className="select"
			classNamePrefix="select"
			name="localization"
			value={selectedLang}
			onChange={handleLang}
			clearable={false}
			isSearchable={false}
			options={languages}
			autosize={false}
		/>
	</div>
);

const PrivacyAndTerms = () => (
	<div className="privacy-and-terms">
		<p>
			<a
				href="//inocrowd-api.prod.brightalgo.tech/api/legaldocument/download?documentType=data.privacy.policy"
				target="_blank"
				rel="noopener noreferrer"
			>
				<FormattedMessage id="footer.privacy.label">
					{txt => <Fragment>{txt}</Fragment>}
				</FormattedMessage>
			</a>
			<FormattedMessage id="footer.and.label">
				{txt => <Fragment>{txt}</Fragment>}
			</FormattedMessage>
			<a
				href="//inocrowd-api.prod.brightalgo.tech/api/legaldocument/download?documentType=terms.of.use"
				target="_blank"
				rel="noopener noreferrer"
			>
				<FormattedMessage id="footer.terms.label">
					{txt => <Fragment>{txt}</Fragment>}
				</FormattedMessage>
			</a>
		</p>
	</div>
);

const Faqs = () => (
	<div className="faqs">
		<Link to="/how-it-works/faq">FAQs</Link>
	</div>
);

const SocialLinks = () => (
	<ul className="social-links">
		<li className="social-link-item">
			<a
				href="https://www.facebook.com/InoCrowd/"
				target="_blank"
				rel="noopener noreferrer"
			>
				<img className="facebook" src={icFacebook} alt="Facebook icon" />
			</a>
		</li>
		<li className="social-link-item">
			<a
				href="https://www.linkedin.com/company/inocrowd"
				target="_blank"
				rel="noopener noreferrer"
			>
				<img className="linkedin" src={icLinkedin} alt="Linkedin icon" />
			</a>
		</li>
		<li className="social-link-item">
			<a
				href="https://twitter.com/InoCrowd"
				target="_blank"
				rel="noopener noreferrer"
			>
				<img className="twitter" src={icTwitter} alt="Twitter icon" />
			</a>
		</li>
		<li className="social-link-item">
			<a
				href="https://www.instagram.com/inocrowd"
				target="_blank"
				rel="noopener noreferrer"
			>
				<img className="instagram" src={icInstagram} alt="Instagram icon" />
			</a>
		</li>
		<li className="social-link-item">
			<a
				href="https://www.youtube.com/channel/UC8JotJA9Txz_6NQXWuEwdvQ"
				target="_blank"
				rel="noopener noreferrer"
			>
				<img className="youtube" src={icYoutube} alt="Youtube icon" />
			</a>
		</li>
	</ul>
);

const FooterCopyright = () => (
	<p className="copyright">
		{`Inocrowd © ${new Date().getFullYear()} `}
		<FormattedMessage id="footer.copyright">
			{txt => <Fragment>{txt}</Fragment>}
		</FormattedMessage>
	</p>
);

const mapToProps = ({
	language,
	languages,
	changeLanguage,
	generalData,
	addresses,
	phones,
}) => ({
	language,
	languages,
	changeLanguage,
	generalData,
	addresses,
	phones,
});

export default connect(
	mapToProps,
	actions,
)(Footer);

export { Footer };
