import Loadable from 'react-loadable';

import { CustomLoadableLoading } from '../../components/loading/loading.js';

const HowItWorksSeekerPageAsync = Loadable({
	loader: () => import(/* webpackChunkName: "how-it-works-seeker-page" */'./how-it-works-seeker.page.js'),
	modules: ['./how-it-works-seeker.page.js'],
	webpack: () => [require.resolveWeak('./how-it-works-seeker.page.js')],
	loading: CustomLoadableLoading,
});


export default HowItWorksSeekerPageAsync;
