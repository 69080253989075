import Loadable from 'react-loadable';

import { CustomLoadableLoading } from '../../components/loading/loading.js';

const CareerSuccessApplyPageAsync = Loadable({
	loader: () => import(/* webpackChunkName: "career-success-apply-page" */'./career-success-apply.page.js'),
	modules: ['./career-success-apply.page.js'],
	webpack: () => [require.resolveWeak('./career-success-apply.page.js')],
	loading: CustomLoadableLoading,
});

export default CareerSuccessApplyPageAsync;
