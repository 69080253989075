import Loadable from 'react-loadable';

import { CustomLoadableLoading } from '../../components/loading/loading.js';

const PartnersApplyPageAsync = Loadable({
	loader: () => import(/* webpackChunkName: "partners-apply-page" */'./partners-apply.page.js'),
	modules: ['./partners-apply.page.js'],
	webpack: () => [require.resolveWeak('./partners-apply.page.js')],
	loading: CustomLoadableLoading,
});

export default PartnersApplyPageAsync;
