import Loadable from 'react-loadable';

import { CustomLoadableLoading } from '../../components/loading/loading.js';

const PartnersPageAsync = Loadable({
	loader: () => import(/* webpackChunkName: "partners-details-page" */ './partners-details.page.js'),
	modules: ['./partners-details.page.js'],
	webpack: () => [require.resolveWeak('./partners-details.page.js')],
	loading: CustomLoadableLoading,
});

export default PartnersPageAsync;
