import Loadable from 'react-loadable';

import { CustomLoadableLoading } from '../../components/loading/loading.js';

const HowItWorksSolverPageAsync = Loadable({
	loader: () => import(/* webpackChunkName: "how-it-works-solver-page" */'./how-it-works-solver.page.js'),
	modules: ['./how-it-works-solver.page.js'],
	webpack: () => [require.resolveWeak('./how-it-works-solver.page.js')],
	loading: CustomLoadableLoading,
});

export default HowItWorksSolverPageAsync;
