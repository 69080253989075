import Loadable from 'react-loadable';

import { CustomLoadableLoading } from '../../components/loading/loading.js';

const NewsListPageAsync = Loadable({
	loader: () => import(/* webpackChunkName: "news-list-page" */'./news-list.page.js'),
	modules: ['./news-list.page.js'],
	webpack: () => [require.resolveWeak('./news-list.page.js')],
	loading: CustomLoadableLoading,
});

export default NewsListPageAsync;
