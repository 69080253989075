import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { NavLink as Link } from 'react-router-dom';

import notFoundImage from '../../assets/illustrations/404.svg';

import './not-found.scss';

const PageNotFound = ({ title }) => (
	<div className="not-found-wrapper">
		{title && <h1>{title}</h1>}
		<img src={notFoundImage} alt="Not found" />
		<div className="center">
			<FormattedMessage id="not-found.not-found-message" />
		</div>
		<Link to="/">
			<FormattedMessage id="not-found.not-found-button" />
		</Link>
	</div>
);

export default injectIntl(PageNotFound);
