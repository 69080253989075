import Loadable from 'react-loadable';

import { CustomLoadableLoading } from '../../components/loading/loading.js';

const CareerApplyPageAsync = Loadable({
	loader: () => import(/* webpackChunkName: "career-apply-page" */'./career-apply.page.js'),
	modules: ['./career-apply.page.js'],
	webpack: () => [require.resolveWeak('./career-apply.page.js')],
	loading: CustomLoadableLoading,
});

export default CareerApplyPageAsync;
