import createStore from 'redux-zero';

function setCookie(name, value, days) {
	let expires = '';
	if (days) {
		const date = new Date();
		date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
		expires = `; expires=${date.toUTCString()}`;
	}
	window.document.cookie = `${name}=${value || ''}${expires}; path=/`;
}

const cleanData = {
	homePageData: null,
	testimonials: null,
	activeChallengesData: [],
	successStories: null,
	successStory: null,
	relatedSuccessStories: null,
	solver: null,
	seeker: null,
	communityPageData: null,
	ourValues: [],
	ourMission: [],
	ourVisionPageData: null,
	whatIsOpenInnovationPageData: null,
	ourTeam: null,
	ourTeamPageData: null,
	whoAreWePageData: null,
	news: null,
	totalNews: null,
	relatedNews: null,
	newsDetail: null,
	careers: null,
	careersPageData: null,
	careersDetail: null,
	faq: [],
	awards: null,
	partnersDetailsPageData: null,
	platformTeaserPageData: null,
};

function initialState({ language, languages, ...props }) {
	return {
		languages,
		language,
		isLoading: false,
		generalData: [],
		addresses: [],
		phones: [],
		...cleanData,
		...props,
	};
}

const actions = () => ({
	changeLanguage: (state, value) => {
		if (state.language.value === value.value) {
			return {};
		}
		setCookie('_language', value.value, 90);
		return {
			language: value,
			...cleanData,
		};
	},
	setLoading: (state, value) => ({
		isLoading: value,
	}),
	setGeneralData: (state, value) => ({
		generalData: value,
	}),
	setAddresses: (state, value) => ({
		addresses: value,
	}),
	setPhones: (state, value) => ({
		phones: value,
	}),
	setHomePageData: (state, value) => ({
		homePageData: value,
	}),
	setTestimonials: (state, value) => ({
		testimonials: value,
	}),
	setActiveChallengesData: (state, value) => ({
		activeChallengesData: value,
	}),
	setSuccessStory: (state, value) => ({
		successStory: value,
	}),
	setRelatedSuccessStories: (state, value) => ({
		relatedSuccessStories: value,
	}),
	setSuccessStories: (state, value) => ({
		successStories: value,
	}),
	setSolver: (state, value) => ({
		solver: value,
	}),
	setSeeker: (state, value) => ({
		seeker: value,
	}),
	setCommunityPageData: (state, value) => ({
		communityPageData: value,
	}),
	setOurValues: (state, value) => ({
		ourValues: value,
	}),
	setOurMission: (state, value) => ({
		ourMission: value,
	}),
	setOurVision: (state, value) => ({
		ourVisionPageData: value,
	}),
	setWhatIsOpenInnovationn: (state, value) => ({
		whatIsOpenInnovationPageData: value,
	}),
	setOurTeam: (state, value) => ({
		ourTeam: value,
	}),
	setOurTeamPageData: (state, value) => ({
		ourTeamPageData: value,
	}),
	setWhoAreWePageData: (state, value) => ({
		whoAreWePageData: value,
	}),
	setNewsList: (state, value) => ({
		news: value,
	}),
	setTotalNews: (state, value) => ({
		totalNews: value,
	}),
	setRelatedNews: (state, value) => ({
		relatedNews: value,
	}),
	setNewsDetail: (state, value) => ({
		newsDetail: value,
	}),
	setCareers: (state, value) => ({
		careers: value,
	}),
	setCareersPageData: (state, value) => ({
		careersPageData: value,
	}),
	setCareersDetail: (state, value) => ({
		careersDetail: value,
	}),
	setFaq: (state, value) => ({
		faq: value,
	}),
	setAwards: (state, value) => ({
		awards: value,
	}),
	setPartnersDetailsPageData: (state, value) => ({
		partnersDetailsPageData: value,
	}),
	setPlatformTeaserPageData: (state, value) => ({
		platformTeaserPageData: value,
	}),
});

let store = null;

function initializeStoreClient(state) {
	store = createStore(initialState(state));
	return store;
}

function initializeStoreServer(state) {
	store = createStore(initialState(state));
	return store;
}

export {
	initializeStoreClient, initializeStoreServer, store, actions,
};
