import Loadable from 'react-loadable';

import { CustomLoadableLoading } from '../../../components/loading/loading.js';

const SuccessStoryPageAsync = Loadable({
	loader: () => import(/* webpackChunkName: "success-story-page" */'./success-story.page.js'),
	modules: ['./success-story.page.js'],
	webpack: () => [require.resolveWeak('./success-story.page.js')],
	loading: CustomLoadableLoading,
});

export default SuccessStoryPageAsync;
