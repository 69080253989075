import React from 'react';
import { Route } from 'react-router-dom';
import { Switch } from 'react-router';
// eslint-disable-next-line import/extensions
import { connect } from 'redux-zero/react';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';
import moment from 'moment';
// eslint-disable-next-line import/extensions
import 'moment/locale/pt';
// eslint-disable-next-line import/extensions
import 'moment/locale/es';
// eslint-disable-next-line import/extensions
import 'moment/locale/fr';
// eslint-disable-next-line import/extensions
import 'moment/locale/zh-cn';

import { Drift } from 'react-drift';

import NotFound from './components/not-found/page-not-found.js';
import Loading from './components/loading/loading.js';
import ErrorBoundary from './components/errors/error-boundary.js';

import Navbar from './layout/navbar.js';
import Footer from './layout/footer.js';
import SuccessStoriesPageAsync from './features/success-stories/success-stories.page.async.js';
import SuccessStoryPageAsync from './features/success-stories/details/success-story.page.async.js';
import WhoAreWePageAsync from './features/who-are-we/who-are-we.page.async.js';
import NewsDetailsPageAsync from './features/news/news-detail.page.async.js';
import NewsListPageAsync from './features/news/news-list.page.async.js';
import HomePageAsync from './features/home/home.page.async.js';
import OurTeamPageAsync from './features/our-team/our-team.page.async.js';
import CommunityPageAsync from './features/community/community.page.async.js';
import HowItWorksSeekerPageAsync from './features/how-it-works-seeker/how-it-works-seeker.page.async.js';
import HowItWorksSolverPageAsync from './features/how-it-works-solver/how-it-works-solver.page.async.js';
import CareersAsyncPage from './features/careers/careers-list.page.async.js';
import CareersDetailsAsyncPage from './features/careers/careers-detail.page.async.js';
import CareerApplyAsyncPage from './features/careers/career-apply.page.async.js';
import CareerSuccessApplyAsyncPage from './features/careers/career-success-apply.page.async.js';
import OurValuesPageAsync from './features/our-values/our-values.page.async.js';
import OurMissionPageAsync from './features/our-mission/our-mission.page.async.js';
import WhatIsOpenInnovationPageAsync from './features/what-is-open-innovation/what-is-open-innovation.page.async.js';
import OurVisionPageAsync from './features/our-vision/our-vision.page.async.js';
import FaqPageAsync from './features/faq/faq.page.async.js';
import AwardsPageAsync from './features/awards/awards.page.async.js';
import PartnersDetailsAsyncPage from './features/partners/partners-details.page.async.js';
import PartnersApplyAsyncPage from './features/partners/partners-apply.page.async.js';
import PartnersApplySuccessAsyncPage from './features/partners/partners-apply-success.page.async.js';

class App extends React.Component {
	state = {
		navbarOpen: false,
		modalOpen: false,
	};

	componentDidMount() {
		const { language } = this.props;

		moment.locale(language.value || 'en');
	}

	shouldComponentUpdate(nextProps) {
		const { language } = nextProps;

		moment.locale(language.value || 'en');

		return true;
	}

	toggleNavbar = () => {
		this.setState(prevState => ({
			navbarOpen: !prevState.navbarOpen,
		}));
	};

	closeNavbar = () => {
		this.setState({
			navbarOpen: false,
		});
	};

	toggleModal = () => {
		this.setState(prevState => ({
			modalOpen: !prevState.modalOpen,
		}));
	};

	closeModal = () => {
		this.setState({
			modalOpen: false,
		});
	};

	render() {
		const { isLoading } = this.props;
		const { navbarOpen, modalOpen } = this.state;

		return (
			<React.Fragment>
				{isLoading && <Loading />}
				<Helmet defaultTitle="Inocrowd" titleTemplate="Inocrowd - %s" />
				<div
					className={`${classNames({
						freeze: navbarOpen || modalOpen,
					})} app-shell`}
				>
					<Navbar
						navbarOpen={navbarOpen}
						toggleNav={this.toggleNavbar}
						closeNavbar={this.closeNavbar}
					/>
					<ErrorBoundary>
						<div className="page-wrapper">
							<Switch>
								<Route exact path="/">
									<HomePageAsync
										modalOpen={modalOpen}
										toggleModal={this.toggleModal}
										closeModal={this.closeModal}
									/>
								</Route>
								<Route
									path="/about/what-is-open-innovation"
									component={WhatIsOpenInnovationPageAsync}
								/>
								<Route path="/about/who-are-we" component={WhoAreWePageAsync} />
								<Route path="/about/our-team" component={OurTeamPageAsync} />
								<Route
									path="/about/our-mission"
									component={OurMissionPageAsync}
								/>
								<Route
									path="/about/our-values"
									component={OurValuesPageAsync}
								/>
								<Route
									path="/about/our-vision"
									component={OurVisionPageAsync}
								/>
								<Route path="/about/community" component={CommunityPageAsync} />
								<Route path="/about/awards" component={AwardsPageAsync} />
								<Route exact path="/news" component={NewsListPageAsync} />
								<Route path="/news/:slug" component={NewsDetailsPageAsync} />
								<Route
									path="/how-it-works/seeker"
									component={HowItWorksSeekerPageAsync}
								/>
								<Route
									path="/how-it-works/solver"
									component={HowItWorksSolverPageAsync}
								/>
								<Route path="/how-it-works/faq" component={FaqPageAsync} />
								<Route
									path="/success-stories/:storyId"
									component={SuccessStoryPageAsync}
								/>
								<Route
									exact
									path="/success-stories"
									component={SuccessStoriesPageAsync}
								/>
								<Route exact path="/careers" component={CareersAsyncPage} />
								<Route
									path="/careers/:slug"
									exact
									component={CareersDetailsAsyncPage}
								/>
								<Route
									path="/careers/:slug/apply"
									exact
									component={CareerApplyAsyncPage}
								/>
								<Route
									path="/careers/apply/success"
									exact
									component={CareerSuccessApplyAsyncPage}
								/>
								<Route
									path="/partners"
									exact
									component={PartnersDetailsAsyncPage}
								/>
								<Route
									path="/partners/apply"
									exact
									component={PartnersApplyAsyncPage}
								/>
								<Route
									path="/partners/apply/success"
									component={PartnersApplySuccessAsyncPage}
								/>

								<Route component={NotFound} />
							</Switch>
						</div>

						{process.env.REACT_APP_DRIFT_ID && (
							<Drift appId={process.env.REACT_APP_DRIFT_ID} />
						)}

						<Footer />
					</ErrorBoundary>
				</div>
			</React.Fragment>
		);
	}
}

const mapToProps = ({ isLoading, language }) => ({
	isLoading,
	language,
});
export default connect(
	mapToProps,
	{},
)(App);
