import Loadable from 'react-loadable';

import { CustomLoadableLoading } from '../../components/loading/loading.js';

const SuccessStoriesPageAsync = Loadable({
	loader: () => import(/* webpackChunkName: "success-stories-page" */'./success-stories.page.js'),
	modules: ['./success-stories.page.js'],
	webpack: () => [require.resolveWeak('./success-stories.page.js')],
	loading: CustomLoadableLoading,
});

export default SuccessStoriesPageAsync;
