import axios from 'axios';

import { logError } from './infrastructure/error-handling.js';

class MainService {
	getGeneralData(language) {
		return axios
			.get(
				`${
					process.env.REACT_APP_API_ENDPOINT
				}/api/singletons/get/business?lang=${language}`,
			)
			.then(res => res)
			.catch(logError);
	}

	getContactsData(language) {
		return axios
			.post(
				`${
					process.env.REACT_APP_API_ENDPOINT
				}/api/singletons/get/contacts?lang=${language}`,
				{ populate: 1 },
			)
			.then(res => res.data)
			.catch(logError);
	}
}

export default new MainService();
