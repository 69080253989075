import en from './en.json';
import pt from './pt.json';
import es from './es.json';
import fr from './fr.json';
import zh from './zh.json';

const Languages = [
	{ code: 'en', label: 'English', value: en },
	{ code: 'pt', label: 'Português', value: pt },
	{ code: 'es', label: 'Español', value: es },
	{ code: 'fr', label: 'Français', value: fr },
	{ code: 'zh', label: '普通話', value: zh },
];

export default Languages;
