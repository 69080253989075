import Loadable from 'react-loadable';

import { CustomLoadableLoading } from '../../components/loading/loading.js';

const AwardsPageAsync = Loadable({
	loader: () => import(/* webpackChunkName: "awards-page" */'./awards.page.js'),
	modules: ['./awards.page.js'],
	webpack: () => [require.resolveWeak('./awards.page.js')],
	loading: CustomLoadableLoading,
});

export default AwardsPageAsync;
