import Loadable from 'react-loadable';

import { CustomLoadableLoading } from '../../components/loading/loading.js';

const CareersDetailPageAsync = Loadable({
	loader: () => import(/* webpackChunkName: "careers-details-page" */'./careers-details.page.js'),
	modules: ['./careers-details.page.js'],
	webpack: () => [require.resolveWeak('./careers-details.page.js')],
	loading: CustomLoadableLoading,
});

export default CareersDetailPageAsync;
