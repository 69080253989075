import Loadable from 'react-loadable';

import { CustomLoadableLoading } from '../../components/loading/loading.js';

const OurMissionPageAsync = Loadable({
	loader: () => import(/* webpackChunkName: "our-mission-page" */'./our-mission.page.js'),
	modules: ['./our-mission.page.js'],
	webpack: () => [require.resolveWeak('./our-mission.page.js')],
	loading: CustomLoadableLoading,
});

export default OurMissionPageAsync;
