import React from 'react';
import ReactDOM from 'react-dom';
import Loadable from 'react-loadable';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider, connect } from 'redux-zero/react';
import { IntlProvider, addLocaleData } from 'react-intl';
import { ParallaxProvider } from 'react-scroll-parallax';
import en from 'react-intl/locale-data/en.js';
import pt from 'react-intl/locale-data/pt.js';
import es from 'react-intl/locale-data/es.js';
import fr from 'react-intl/locale-data/fr.js';
import zh from 'react-intl/locale-data/zh.js';

import App from './app.js';
import GoogleAnalytics from './infrastructure/ga.js';
import { initializeStoreClient, actions } from './shared/store.js';

// import the i18n messages
import I18NLanguages from './i18n/index.js';

// TODO: get the supports languages from the CMS
import {
	supportedLanguages,
	defaultSupportedLanguage,
} from './shared/supported-languages.js';

import './index.scss';

addLocaleData([...en, ...pt, ...es, ...fr, ...zh]);

function getCookie(name) {
	const nameEQ = `${name}=`;
	const ca = window.document.cookie.split(';');
	for (let i = 0; i < ca.length; i += 1) {
		let c = ca[i];
		while (c.charAt(0) === ' ') c = c.substring(1, c.length);
		if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
	}
	return null;
}

const mapToProps = ({ language, languages }) => ({
	language,
	languages,
});

const intlMessages = I18NLanguages.reduce((result, language) => {
	// eslint-disable-next-line no-param-reassign
	result[language.code] = language.value;
	return result;
}, {});

const AppWrapper = connect(
	mapToProps,
	actions,
)(props => (
	<IntlProvider
		locale={props.language.value}
		messages={intlMessages[props.language.value]}
	>
		<ParallaxProvider>
			<Router>
				<GoogleAnalytics>
					<App />
				</GoogleAnalytics>
			</Router>
		</ParallaxProvider>
	</IntlProvider>
));

const StoreAppWrapper = () => (
	<Provider
		store={initializeStoreClient(
			Object.assign(
				{
					language:
						supportedLanguages.find(
							language => language.value === getCookie('_language'),
						) || defaultSupportedLanguage,
					languages: supportedLanguages,
				},
				window.__APP_STATE__,
			),
		)}
	>
		<AppWrapper />
	</Provider>
);

function supportsIntersectionObserver() {
	return (
		'IntersectionObserver' in global
		&& 'IntersectionObserverEntry' in global
		&& 'intersectionRatio' in IntersectionObserverEntry.prototype
	);
}

function initApp() {
	// eslint-disable-next-line no-underscore-dangle
	if (window.__SSR__) {
		window.onload = () => {
			Loadable.preloadReady().then(() => {
				ReactDOM.hydrate(<StoreAppWrapper />, document.getElementById('root'));
			});
		};
	} else {
		ReactDOM.render(<StoreAppWrapper />, document.getElementById('root'));
	}
}

if (!supportsIntersectionObserver()) {
	import('intersection-observer').then(() => {
		initApp();
	});
} else {
	initApp();
}
