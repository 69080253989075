import Loadable from 'react-loadable';

import { CustomLoadableLoading } from '../../components/loading/loading.js';

const NewsDetailPageAsync = Loadable({
	loader: () => import(/* webpackChunkName: "news-details-page" */'./news-details.page.js'),
	modules: ['./news-details.page.js'],
	webpack: () => [require.resolveWeak('./news-details.page.js')],
	loading: CustomLoadableLoading,
});

export default NewsDetailPageAsync;
