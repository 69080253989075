import Loadable from 'react-loadable';

import { CustomLoadableLoading } from '../../components/loading/loading.js';

const OurVisionPageAsync = Loadable({
	loader: () => import(/* webpackChunkName: "our-vision-page" */'./our-vision.page.js'),
	modules: ['./our-vision.page.js'],
	webpack: () => [require.resolveWeak('./our-vision.page.js')],
	loading: CustomLoadableLoading,
});

export default OurVisionPageAsync;
