import Loadable from 'react-loadable';

import { CustomLoadableLoading } from '../../components/loading/loading.js';

const CareersListPageAsync = Loadable({
	loader: () => import(/* webpackChunkName: "careers-list-page" */'./careers-list.page.js'),
	modules: ['./careers-list.page.js'],
	webpack: () => [require.resolveWeak('./careers-list.page.js')],
	loading: CustomLoadableLoading,
});

export default CareersListPageAsync;
