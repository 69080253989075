import Loadable from 'react-loadable';

import { CustomLoadableLoading } from '../../components/loading/loading.js';

const PartnersApplySuccessPageAsync = Loadable({
	loader: () => import(/* webpackChunkName: "partners-apply-success" */'./partners-apply-success.page.js'),
	modules: ['./partners-apply-success.page.js'],
	webpack: () => [require.resolveWeak('./partners-apply-success.page.js')],
	loading: CustomLoadableLoading,
});

export default PartnersApplySuccessPageAsync;
