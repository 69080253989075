import React, { Fragment } from 'react';
import Select from 'react-select';
import classNames from 'classnames';
import onClickOutside from 'react-onclickoutside';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router';
import { NavLink as Link } from 'react-router-dom';
// eslint-disable-next-line import/extensions
import { connect } from 'redux-zero/react';
import { Collapse } from 'react-collapse';

import { actions } from '../shared/store.js';
import MainService from '../main-service.js';

import icAngle from '../assets/icons/ic-angle.svg';

import './navbar.scss';

class Navbar extends React.Component {
	static prefetchData = async language => Promise.all([MainService.getGeneralData(language)]);

	state = {
		selectedSubmenu: null,
	};

	componentDidMount() {
		const { generalData } = this.props;

		if (generalData.length === 0) {
			this.fetchData();
		}
	}

	componentDidUpdate(prevProps) {
		const { language } = this.props;

		if (prevProps.language.value !== language.value) {
			this.fetchData();
		}
	}

	handleLang = (selectedLang) => {
		const { changeLanguage } = this.props;
		changeLanguage(selectedLang);
	};

	handleNavbarMenu = () => {
		const { toggleNav } = this.props;
		toggleNav();
	};

	handleSubmenu = (index) => {
		const { selectedSubmenu } = this.state;

		if (selectedSubmenu === index) {
			this.setState({
				selectedSubmenu: null,
			});
		} else {
			this.setState({
				selectedSubmenu: index,
			});
		}
	};

	handleNavigationClick = () => {
		const { closeNavbar } = this.props;

		closeNavbar();
		this.closeCollapse();
	};

	closeCollapse = () => {
		this.setState({
			selectedSubmenu: null,
		});
	};

	fetchData() {
		const { language, setGeneralData } = this.props;

		Navbar.prefetchData(language.value)
			.then(({ data }) => {
				setGeneralData(data);
			})
			.catch((error) => {
				console.log(error);
			});
	}

	render() {
		const { selectedSubmenu } = this.state;
		const {
			navbarOpen, language, generalData, languages,
		} = this.props;

		return (
			<div className="site-navbar">
				<div className="container container-fluid">
					<div className="row">
						<div className="col-xs-6 col-md-3 start-xs middle-xs">
							{generalData
								&& generalData.logo
								&& generalData.logo.path && (
								<NavLogo path={generalData.logo.path} />
							)}
						</div>
						<div className="col-xs-6 col-md-9 end-xs middle-xs">
							<NavList isOpen={navbarOpen}>
								<NavSubMenuTitle
									childPath="/about/"
									label="navigation.about"
									labelOnly
									index={0}
									isOpen={selectedSubmenu === 0}
									handleClick={this.handleSubmenu}
									closeCollapse={this.closeCollapse}
								>
									<NavItem
										path="/about/what-is-open-innovation"
										label="navigation.about.whatIsOpenInnovation"
										handleNavigationClick={this.handleNavigationClick}
									/>
									<NavItem
										path="/about/who-are-we"
										label="navigation.about.whoAreWe"
										handleNavigationClick={this.handleNavigationClick}
									/>
									<NavItem
										path="/about/our-mission"
										label="navigation.about.ourMission"
										handleNavigationClick={this.handleNavigationClick}
									/>
									<NavItem
										path="/about/our-values"
										label="navigation.about.ourValues"
										handleNavigationClick={this.handleNavigationClick}
									/>
									<NavItem
										path="/about/our-vision"
										label="navigation.about.ourVision"
										handleNavigationClick={this.handleNavigationClick}
									/>
									<NavItem
										path="/about/community"
										label="navigation.about.community"
										handleNavigationClick={this.handleNavigationClick}
									/>
									<NavItem
										path="/about/our-team"
										label="navigation.about.team"
										handleNavigationClick={this.handleNavigationClick}
									/>
									<NavItem
										path="/about/awards"
										label="navigation.about.awards"
										handleNavigationClick={this.handleNavigationClick}
									/>
								</NavSubMenuTitle>
								<NavItem
									path="/news"
									label="navigation.news"
									handleNavigationClick={this.handleNavigationClick}
								/>
								<NavSubMenuTitle
									childPath="/how-it-works/"
									label="navigation.howItWorks"
									labelOnly
									index={2}
									isOpen={selectedSubmenu === 2}
									handleClick={this.handleSubmenu}
									closeCollapse={this.closeCollapse}
								>
									<NavItem
										path="/how-it-works/seeker"
										label="navigation.howItWorks.seeker"
										handleNavigationClick={this.handleNavigationClick}
									/>
									<NavItem
										path="/how-it-works/solver"
										label="navigation.howItWorks.solver"
										handleNavigationClick={this.handleNavigationClick}
									/>
									<NavItem
										path="/how-it-works/faq"
										label="navigation.faq"
										handleNavigationClick={this.handleNavigationClick}
									/>
								</NavSubMenuTitle>
								<NavItem
									path="/success-stories"
									label="navigation.successStories"
									handleNavigationClick={this.handleNavigationClick}
								/>
								<NavItem
									path="https://platform.inocrowd.com.pt"
									externalLink
									label="navigation.joinUs"
									handleNavigationClick={this.handleNavigationClick}
								/>
							</NavList>
							<NavMenuButton
								isOpen={navbarOpen}
								handleClick={this.handleNavbarMenu}
							/>
							<I18N
								languages={languages}
								selectedLang={language}
								handleLang={this.handleLang}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const NavLogo = ({ path }) => (
	<Link to="/">
		<img
			className="logo"
			src={process.env.REACT_APP_API_ENDPOINT + path}
			alt=""
		/>
	</Link>
);

const NavList = ({ children, isOpen }) => (
	<nav className={`${classNames({ open: isOpen })} nav-wrapper`}>
		<ul className="nav-list">{children}</ul>
	</nav>
);

const NavItem = ({
	path, label, externalLink, handleNavigationClick,
}) => (
	<li className="nav-item">
		{!externalLink && (
			<Link to={path} onClick={handleNavigationClick} activeClassName="active">
				<FormattedMessage id={label}>
					{txt => <Fragment>{txt}</Fragment>}
				</FormattedMessage>
			</Link>
		)}
		{externalLink && (
			<a
				href={path}
				target="_blank"
				rel="noopener noreferrer"
				onClick={handleNavigationClick}
			>
				<FormattedMessage id={label}>
					{txt => <Fragment>{txt}</Fragment>}
				</FormattedMessage>
			</a>
		)}
	</li>
);

// eslint-disable-next-line react/no-multi-comp
class NavSubMenuTitleInternal extends React.Component {
	constructor(props) {
		super(props);
		this.navItemRef = React.createRef();
	}

	render() {
		const {
			label,
			isOpen,
			handleClick,
			index,
			location,
			childPath,
			children,
			closeCollapse,
		} = this.props;
		return (
			<li className="nav-item">
				<span
					className={`${classNames({
						open: isOpen,
						hasActiveChild: location.pathname.startsWith(childPath),
					})} submenu-title`}
					onClick={() => handleClick(index)}
					ref={this.navItemRef}
				>
					<FormattedMessage id={label}>
						{txt => <Fragment>{txt}</Fragment>}
					</FormattedMessage>
					<img src={icAngle} alt="" />
				</span>
				{children && (
					<NavSubMenuItemsWrapper
						isOpen={isOpen}
						closeCollapse={closeCollapse}
						parentRef={this.navItemRef}
					>
						{children}
					</NavSubMenuItemsWrapper>
				)}
			</li>
		);
	}
}

const NavSubMenuTitle = withRouter(NavSubMenuTitleInternal);

// eslint-disable-next-line react/no-multi-comp
class NavSubMenuItemsWrapperInternal extends React.Component {
	constructor(props) {
		super(props);

		this.collapseRef = React.createRef();
	}

	handleClickOutside = (evt) => {
		const { isOpen, closeCollapse, parentRef } = this.props;
		if (!isOpen || evt.target === this.collapseRef.current) {
			return;
		}

		if (isOpen && evt.target === parentRef.current) {
			return;
		}

		if (closeCollapse) {
			closeCollapse();
		}
	};

	render() {
		const { isOpen, children } = this.props;

		return (
			<Collapse
				className="collapse-wrapper"
				isOpened={isOpen}
				ref={this.collapseRef}
			>
				<div className="submenu-wrapper">
					<ul className="submenu">{children}</ul>
				</div>
			</Collapse>
		);
	}
}
const NavSubMenuItemsWrapper = onClickOutside(NavSubMenuItemsWrapperInternal);

const NavMenuButton = ({ isOpen, handleClick }) => (
	<button
		className={`${classNames({ open: isOpen })} nav-menu-button`}
		onClick={handleClick}
	>
		<span />
		<span />
		<span />
		<span />
		<span />
		<span />
	</button>
);

const I18N = ({ languages, selectedLang, handleLang }) => (
	<div className="i18n">
		<Select
			className="select"
			classNamePrefix="select"
			name="localization"
			value={selectedLang}
			placeholder=""
			onChange={handleLang}
			clearable={false}
			isSearchable={false}
			options={languages}
			autosize={false}
		/>
	</div>
);

const mapToProps = ({
	language, languages, changeLanguage, generalData,
}) => ({
	language,
	languages,
	changeLanguage,
	generalData,
});
export default connect(
	mapToProps,
	actions,
)(Navbar);

export { Navbar };
